import React, { useContext } from 'react'
import { navigate } from 'gatsby'

import CustomQuoteLayout from '../../../views/custom-quote/components/CustomQuoteLayout'
import FormFileUpload from '../../../views/custom-quote/components/FormFileUpload'
import FormNavigation from '../../../views/custom-quote/components/FormNavigation'
import FormQuestion from '../../../views/custom-quote/components/FormQuestion'

import { CustomQuoteContext } from '../../../views/custom-quote/context/CustomQuoteManager'
import { CUSTOM_QUOTE_SEQUENCE_LINKS } from '../../../views/custom-quote/constants'

const FileUpload = () => {
  const { formData, updateFormData } = useContext(CustomQuoteContext)
  const { has3DModel } = formData
  const handleFileProcess = (files) => {
    // file only gets process and uploaded later
    updateFormData({
      ...formData,
      files: files
    })
  }

  const handleSubmit = () => {
    navigate(CUSTOM_QUOTE_SEQUENCE_LINKS.fileUpload.next)
  }

  const description = has3DModel ? 
    `We accept any 3D file format but we recommend sending .STL or .OBJ for easier processing.` :
    `Send us any pictures, sketches or anything related to help us get a better understanding of your project.
     You can also send us your NDA here.`

  return (
    <CustomQuoteLayout>
      <FormQuestion title="Upload your files here" description={description} />
      <FormFileUpload
        getFiles={files => handleFileProcess(files)}
        defaultFiles={formData && formData.files}
      />
      <FormNavigation
        showBackButton
        onSubmit={handleSubmit}
        previousLink={CUSTOM_QUOTE_SEQUENCE_LINKS.fileUpload.previous}
      />
    </CustomQuoteLayout>
  )
}

export default FileUpload
