// TODO:
import React, { useState, useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { css } from 'styled-components'
import { MdDelete } from 'react-icons/md'

import { colors, fontSizes, space, palette } from '../../../utils/tokens'

const FormFileUpload = ({
  getFiles,
  defaultFiles,
}) => {

  const [files, setFiles] = useState([])

  useEffect(() => {
    if(defaultFiles && defaultFiles.length > 0) {
      setFiles(defaultFiles)
    }
  }, [])

  useEffect(() => {
    getFiles && getFiles(files)
  }, [files])

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader()

      reader.readAsArrayBuffer(file)

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const result = reader.result
        
        setFiles(prev => [...prev, {
          name: file.name,
          blob: result,
        }])
      }
    })
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop
  })

  const handleRemove = (index) => {
    const filteredFiles = files.filter((_, id) => id !== index)
    setFiles(filteredFiles)
  }

  return (
    <div>
      <div
        css={css({
          padding: `${space[4]}px ${space[5]}px`,
          border: `2px dashed ${colors.darkGray}`,
          borderRadius: `8px`,
          backgroundColor: colors.mute,
        })}
        {...getRootProps({ refKey: `innerRef` })}
      >
        <input {...getInputProps()} />
        <div
          css={css({
            textAlign: `center`,
          })}
        >
          <p
            css={css({
              fontWeight: `bold`,
              fontSize: fontSizes[2],
              color: `${palette.grey[70]}`,
              marginBottom: `${space[1]}px`,
            })}
          >
            Drop your files or click here
          </p>
          <p
            css={css({
              fontSize: fontSizes[1],
            })}
          >
            We only accept files under 300Mb
          </p>
        </div>
      </div>
      <div
        css={css({
          marginTop: `${space[4]}px`,
          display: `grid`,
          gridRowGap: `${space[2]}px`,
        })}
      >
        {files.length > 0
          && files.map((file, id) => {
            return (
              <FileCard
                key={id}
                name={file.name}
                onRemove={() => handleRemove(id)}
              />
            )
          })}
      </div>
    </div>
  )
}

const FileCard = ({
  name,
  onRemove,
}) => {
  return (
    <div
      css={css({
        boxShadow: `inset 8px 0 0 0 ${palette.orange[50]}`,
        padding: `${space[3]}px ${space[3]}px`,
        backgroundColor: palette.grey[10],
        border: `1px solid ${palette.grey[20]}`,
        borderLeft: `none`,
        display: `flex`,
        justifyContent: `space-between`,
        alignItems: `baseline`,
        overflow: `hidden`,
      })}
    >
      <span
        css={css({
          whiteSpace: `nowrap`,
          textOverflow: `ellipsis`,
          overflow: `hidden`,
        })}
      >{name}</span>
      <button
        onClick={onRemove}
        aria-label="Delete"
        css={css({
          border: `none`,
          background: `unset`,
          padding: 0,
          cursor: `pointer`,
          color: palette.grey[70],
        })}
      >
        <MdDelete />
      </button>
    </div>
  )
}

export default FormFileUpload